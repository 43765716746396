@import "_pxTOvw.scss";
@import "animate";
@import "jquery.fancybox.min";

$osn-col: #fff;

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #000;
}

::-webkit-scrollbar-thumb:window-inactive {
  background: $osn-col;
}

input:focus::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: transparent;
}
input:focus::-moz-placeholder { /* Firefox 19+ */
  color: transparent;
}
input:focus:-ms-input-placeholder { /* IE 10+ */
  color: transparent;
}
input:focus:-moz-placeholder { /* Firefox 18- */
  color: transparent;
}

a, a:focus, button, button:focus, input, input:focus, textarea, textarea:focus {
  outline: 0 !important;
}

:focus {
  outline: 0 !important;
  outline-offset: 0 !important;
}

select, select:focus {
  outline: 0 !important;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  position: relative;
}

p {
  line-height: 100%;
  white-space: pre-line;
  font-size: $a24;
  line-height : $a29;
}

a:focus, a:hover {
  text-decoration: none; }

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: #000;
  cursor: pointer;
  font-size: $a18;

}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
  transition-delay: .5s;
}

.wow {
  visibility: hidden;
}

.sidebar {
    padding-bottom : $a20;
    background: #F6F6F6;
    width : $a270;
    .pages-wrapper {
      padding-top : $a5;
      border-top: $a5 solid #303030;
      .title_p, .sidebar_footer {
        width : 100%;
        margin-bottom : $a13;
        align-items: flex-end;
        display: flex;
        .sidebar_logo, .footer_logo {
          margin-right: $a5;
          width: $a56;
          height: $a50;
          background: #303030;
          border-radius: $a4;
          img {
            width : 100%;
          }
        }
        h2 {
          padding-bottom : $a6;
          border-bottom: 1px solid #303030;
        }
      }
      .sidebar_footer {
        margin-bottom : 0;
      }
      .catalog {
        margin-bottom : $a15;
        padding-left : $a10;
        .item {
          margin-bottom : $a5;
          a {
            display: flex;
            position: relative;
            white-space: pre-line;
            font-family: MontserratRegular;
            font-size: $a18;
            line-height: $a29;
            color: #303030;
            padding-left : $a21;
            &:before {
              transition: .5s all;
              content: "";
              position: absolute;
              left : 0;
              width: 0;
              height: 0;
              top : $a7;
              border-top: $a7 solid transparent;
              border-left: $a11 solid #303030;
              border-bottom: $a7 solid transparent;
            }
            &:hover:before {
              left: $a5;
            }
          }
        }
      }
    }
}



.hideShow {
  display: none;
}

.show {
  display: block;
}

.information_block {
  .container {
    padding-bottom : $a340;
    position: relative;
    padding-left : $a400;
    .abs_img1 {
      position: absolute;
      left : 0;
      top : 0;
      z-index : 1;
      img {
        width : $a370;
      }
    }
    .abs_img2 {
      position: absolute;
      left : $a13;
      top : 0;
      img {
        width : $a370;
      }
    }
    p {
      font-family: MontserratRegular;
      line-height: $a29;
      color: #000000;
    }
    .bold {
      display: inline-block;
      font-family: MontserratBold;
      border-bottom: 1px solid #000;
    }
    .m {
      margin: $a12 0 $a49;
    }
    .button {
      border: 1px solid #000;
      color: #000;
      &:hover {
        background: #000;
        color: #fff;
      }
    }
  }
}

.button, .button_form {
  font-family: MontserratRegular;
  padding: 0 $a64;
  height: $a60;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height : $a29;
  background: none;
  font-size: $a24;
  border: 1px solid $osn-col;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.5s;
  width: fit-content;
  &:before {
    content: '';
    font-size: 0;
    transition: all 0.5s;
  }
  &.error {
    background: red !important;
    font-size: 0;
    color: #fff !important;
    &:before {
      content: 'Ошибка отправки формы';
      font-size: $a24;
    }
  }
  &.ok {
    background: green !important;
    font-size: 0;
    color: #fff !important;
    &:before {
      content: 'Форма успешно отправлена';
      font-size: $a24;
    }
  }
  &:hover {
    background-color: #fff;
    color: #000;
  }
  &.small {
    padding: 0 $a10;
    height: $a36;
    font-size: $a14;
    img , svg{
      height: $a16;
      margin-right: $a6;
      transition: all 0.5s;
    }
    &:hover {
      color: #000;
      img, svg {
        filter: invert(1);
      }
    }
  }
  &.white {
    border-color: #fff;
    background: transparent;
    &:hover {
      background: #fff;
    }
  }
}

select::-ms-expand {
  display: none;
}

body {
  position: relative;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  color: #000000;
  background: #fff;
  font-size: $a18;
  font-family: 'MontserratRegular';
  .off {
    overflow: hidden;
  }
}

#header {
  .burger {
    display: none;
  }
  .header-bg {
    width: 100%;
    height: $a150;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur($a30);
    display: flex;
    align-items: center;
    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .logo {
        img {
          width : $a80;
        }
        .logo_text {
          padding-left : $a2;
          text-transform : uppercase;
          font-family: 'MontserratSemiBold';
          line-height: $a29;
          letter-spacing: 0.12em;
          color: #fff;
        }
      }
      .info {
        .address {
          font-family: MontserratRegular;
          font-style: normal;
          font-size: $a14;
          line-height: $a17;
          color: #fff;
        }
      }
      .wrap {
        .arrow {
          width : $a16;
            transition: .5s all;
        }
        &:hover .arrow {
          transform: rotate(180deg);
        }
        .phone, .ru_en_ua {
          margin-bottom : $a3;
          background: none;
          border: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          font-family: MontserratRegular;
          font-style: normal;
          font-weight: normal;
          font-size: $a24;
          line-height: $a29;
          cursor: pointer;
          color: #fff;
          option {
            cursor: pointer;
            color: #000;
          }
        }
        .ru_en_ua {
          text-transform : uppercase;
        }
      }
      .wrap1 {
        display: flex;
        align-items: center;
      }
      //.search {
      //  border: none;
      //  background: none;
      //  img {
      //    width : $a45;
      //  }
      //}
    }
  }
  .header_bottom {
    padding-top : $a20;
    .container {
      .nav {
        width : 100%;
        .menu {
          display: flex;
          justify-content: space-between;
          li {
            a {
              font-family: MontserratRegular;
              font-size: $a30;
              line-height: $a37;
              color: #fff;
              text-transform : uppercase;
              .mobile {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

#footer {
  height : $a540;
  background: url("../img/footer/bg_footer.png") no-repeat;
  .wrapper_form {
    .popup {
      background: rgba(0, 0, 0, .5);
      position: fixed;
      height : 100%;
      width : 100%;
      top : 0;
      left : 0;
      display: flex;
      align-items: center;
      justify-content : center;
      z-index : 1000;
      .form {
        position: relative;
        padding-top : $a33;
        width: $a650;
        height: $a740;
        background: rgba(35, 35, 35, 0.9);
        box-shadow: $a1 $a1 $a10 rgba(0, 0, 0, 0.4), $a1 $a1 $a10 rgba(255, 255, 255, 0.1);
        backdrop-filter: blur($a50);
        border-radius: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .close {
          background: none;
          border: none;
          position: absolute;
          cursor: pointer;
          right : $a33;
          top : $a20;
          font-family: MontserratRegular;
          font-size: $a30;
          line-height: $a37;
          color: #fff;
        }
        .title_form {
          font-family: MontserratBold;
          font-size: $a30;
          line-height: $a37;
          color: #fff;
        }
        label {
          margin-top : $a60;
          display: flex;
          align-items: center;
          width : $a510;
          height : $a40;
          img {
            width : $a40;
          }
          input {
            padding-left : $a15;
            width : 100%;
            border-bottom: 1px solid #fff;
            border-left: none;
            border-top: none;
            border-right: none;
            background: none !important;
            height : 100%;
            font-family: MontserratRegular;
            font-size: $a18;
            line-height: $a22;
            color: #666666;
          }
        }
        .textarea {
          resize: none;
          background: none;
          padding: $a26 $a22 0;
          width: $a470;
          height: $a260;
          border: 1px solid #fff;
          border-radius: $a40;
          margin-top : $a38;
          font-family: MontserratRegular;
          font-size: $a18;
          line-height: $a22;
          color: #666666;
          &:focus::-webkit-input-placeholder {
            color: transparent;
          }
        }
        .button_form {
          margin-top : $a70;
        }
      }
    }
  }
  .container {
    display: flex;
    justify-content : space-between;
    padding-top : $a57;
    .contacts {
      .title {
        display: flex;
        font-family: MontserratBold;
        font-size: $a48;
        line-height: $a59;
        color: #fff;
        margin-bottom : $a20;
      }
      p {
        font-family: MontserratRegular;
        line-height: $a29;
        color: #fff;
        img {
          width : $a24;
          margin-right : $a5;
        }
      }
      .mail {
        margin: $a20 0;
      }
    }
    .map {
      width: $a550;
      height: $a400;
      #map {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
        }
      }
    }
  }
}

.up {
  position: fixed;
  right: $a200;
  bottom: $a100;
  transition: all 0.5s;
  opacity: 0;
  z-index: -100;
  img {
    width: $a70;
  }
  &.active {
    z-index: 100;
    opacity: 1;
    &.white {
      filter: invert(1);
    }
  }
}

.container {
  width  : $a1170;
  margin : 0 auto;
}


button {
  cursor: pointer;
  &:active {
    box-shadow: inset 0 5px 10px 0 rgba(0,0,0,0.5) !important;
  }
}

h1 {
  font-family: MontserratBold;
  font-size: $a48;
  line-height: $a59;
  color: #fff;
}

h2 {
    font-family: MontserratBold;
    font-size: $a24;
    line-height: $a29;
    color: #303030;
}

@media screen and (max-width: 1024px) {
  #footer {
    .container {
      .map {
        height : $m73;
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  ::-webkit-scrollbar {
    display : none !important;
  }

  //@media screen and (max-width: 1023px) {
  //  .img_box1, .img_box2, .img_box3, .img_box4, .img_box5 {
  //    display : none;
  //  }
  //}

  h2 {
  //  text-align   : left !important;
  //  line-height  : $m25 !important;
  //  padding-left : $m5 !important;
    font-size    : $m6 !important;
  //  white-space  : normal !important;
  //  margin       : $m10 auto !important;
  //  width        : fit-content;
  }

  h3 {
    font-size      : $m20 !important;
    padding-bottom : $m18 !important;
  }

  h4 {
    text-align   : left !important;
    line-height  : $m25 !important;
    padding-left : $m5 !important;
    font-size    : $m18 !important;
    white-space  : normal !important;
    margin       : $m10 auto !important;
    width        : fit-content;
  }

  p {
    font-size     : $m8 !important;
    white-space   : normal !important;
    text-align    : left !important;
    line-height   : $m8 !important;
    hyphens       : auto;
    padding-right : $m5 !important;
  }

  .container {
    width : 95% !important;
  }

  .sidebar {
    width : 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom : $m8;
    margin-bottom : $m5;
    .pages-wrapper {
      width : 22%;
      .title_p, .sidebar_footer {
        .sidebar_logo, .footer_logo {
          margin-right : $m2;
          width : $m15;
          height: auto;
        }
        h2 {
          padding-bottom : $m3;
        }
      }
      .catalog {
        margin-top : $m5;
        .item {
          margin-bottom : $m4;
          a {
            font-size : $m5;
            padding-left : $m8;
            &:before {
              border-top: .58458vw solid transparent;
              border-left: .87292vw solid #303030;
              border-bottom: .58458vw solid transparent;
            }
          }
        }
      }
    }
  }

  .button, .button_form {
    font-size     : $m8;
    height        : $m24;
    padding       : 0 $m15;
    //border-radius : $m3;
    &.small {
      font-size : $m14;
      height    : $m30;
      padding   : 0 $m10;
    }
  }

  #header {
    height: $m90;
    .burger {
      display: block;
      width: $m41;
    }
    .header_bottom {
      position: absolute;
      width: 100%;
      background: rgba(0, 0, 0, 0.3) !important;
      backdrop-filter: blur($a30);
      //height: calc(100vh - #{$m90});
      display: none;
      height: auto;
      z-index : 10;
      .container .nav .menu {
        display: flex;
        flex-direction: column;
        align-items: center;
        li {
          padding: $m20 0;
          a {
            font-size: $m30;
            line-height: inherit;
            text-align: center;
            .desktop {
              display: none;
            }
            .mobile {
              display: block;
            }
          }
        }
      }
    }
    .header-bg {
      padding: $m5 0;
      height: auto;
      .container {
        flex-flow: row wrap;
        .logo {
          display: flex;
          flex-direction: column;
          align-items: center;
          a {
            img {
              width : $m25;
            }
          }
          p {
            font-size : $m5;
            padding-right : 0 !important;
          }
        }
        .info, .language {
          order: 1;
          display: none;
        }
        .language {
          .wrap {
            .arrow {
              width: $m12;
            }
            select {
              font-size: $m16;
              line-height: initial;
            }
          }
        }
        .info {
          margin: 0 auto;
          padding-left: $m45;
          .wrap {
            .arrow {
              width: $m12;
            }
            select {
              font-size: $m16;
              line-height: initial;
            }
          }
          .address {
            font-size: $m9;
            text-align: center;
            margin-top: $m3;
            width: 100%;
          }
        }
        .search {
          display: none;
        }
        .button {
          height : $m20;
          margin: 0 $m20;
        }
      }
    }
  }

  input[placeholder] {
    text-overflow:ellipsis;
  }

  #footer {
    height: auto;

    .wrapper_form {
      .popup {
        .form {
          padding-top : $m35;
          width: 80%;
          height: $m360;
          .close {
            right : $m20;
            top : $m20;
            font-size: $m12;
            line-height: $a15;
          }
          .title_form {
            font-size: $m20 !important;
            line-height: $a22 !important;
          }
          label {
            margin-top : $m30;
            width : 75%;
            height : $m30;
            img {
              width : $m30;
            }
            input {
              padding-left : $m15;
              width : 100%;
              height : 100%;
              font-size: $m16;
              line-height: $m20;
            }
          }
          .textarea {
            resize: none;
            background: none;
            padding: $m15 $m13 0;
            width: 75%;
            height: $m100;
            margin-top : $m15;
            font-size: $m12;
            line-height: $m15;
          }
          .button_form {
            margin-top : $m15;
            height : $m40;
            width : 70%;
            font-size : $m16;
            line-height : $m20;
          }
        }
      }
    }


    .container {
      padding-top: $m25 ;
      padding-bottom: $m25 ;
      justify-content : center;
      .map {
        display: none;
      }
      .contacts {
        p {
          margin-bottom : $m3;
          text-align: center !important;
          img {
            width : $m7;
          }
        }
        .title {
          margin-bottom : $m10;
          display: flex;
          justify-content : center;
          font-size : $m14;
        }
      }
    }
  }

}

@media screen and (width: 768px) {
  #footer {
    .wrapper_form {
      .popup {
        .form {
          padding-top : $m20;
          width: 80%;
          height: $m220;
          .close {
            right : $m10;
            top : $m10;
            font-size: $m12;
            line-height: $a15;
          }
          .title_form {
            font-size: $m20 !important;
            line-height: $a22 !important;
          }
          label {
            margin-top : $m10;
            width : 75%;
            height : $m30;
            img {
              width : $m20;
            }
            input {
              padding-left : $m5;
              width : 100%;
              height : 100%;
              font-size: $m12;
              line-height: $m15;
            }
          }
          .textarea {
            resize: none;
            background: none;
            padding: $m5 $m5 0;
            width: 75%;
            height: $m50;
            margin-top : $m15;
            font-size: $m12;
            line-height: $m15;
          }
          .button_form {
            margin-top : $m10;
            height : $m20;
            width : 70%;
            font-size : $m12;
            line-height : $m15;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  #header {
    .header-bg {
      padding: $m15 0;
      .container {
        .logo {
          a {
            img {
              width : $m50;
            }
          }
          .logo_text {
            font-size : $m12 !important;
          }
        }
        .button {
          height : $m35;
          font-size : $m12;
          padding : 0 $m25;
        }
      }
    }
  }

  .sidebar {
     padding-bottom: $m20;
     margin-bottom : $m25;
    .pages-wrapper {
      padding-top : $m15;
      width : 100%;
      border-top: $m5 solid #303030;
      .title_p, .sidebar_footer {
        .sidebar_logo, .footer_logo {
          width : auto;
          border-radius: $m4;
          margin-right : $m5;
          img {
            width : $m50;
          }
        }
        h2 {
          font-size : $m26 !important;
          line-height : normal;
        }
      }
      .catalog {
        padding-left : $m7;
        .item {
          margin-bottom : $m8;
          a {
            padding-left : $m20;
            font-size : $m18;
            line-height : $m20;
            &:before {
              top : $m5;
              border-top: 1.88458vw solid transparent;
              border-left: 2.37292vw solid #303030;
              border-bottom: 1.88458vw solid transparent;
            }
          }
        }
      }
    }
  }


  #footer {
    .container {
      .contacts {
        p {
          display: flex;
          align-items: center;
          justify-content : center;
          font-size : $m14 !important;
          line-height : $m16 !important;
          img {
            width : $m18;
          }
        }
        .title {
          margin-bottom : $m10;
          display: flex;
          justify-content : center;
          font-size : $m26;
          line-height : $m30;
        }
      }
    }
  }
}
