@import "_all-page.scss";

#header {
    width : 100%;
    position: absolute;
    top : 0;
    z-index : 1;
}

#main {
    .slider {
        margin-bottom : $a50;
        .slide {
            height : 100%;
            position: relative;
            display: flex;
            justify-content : center;
            picture {
                display: flex;
                align-items: flex-end;
                img {
                    width : 100%;
                }
            }
            .container {
                top : $a350;
                position: absolute;
                h1 {
                    white-space: pre-line;
                    margin-bottom : $a60;
                }
                .button {
                    padding : 0 $a77;
                }
            }
        }
    }
    .materials {
        .container {
            display: flex;
            justify-content : space-between;
            align-items: flex-start;
            .content {
                width : $a870;
                display: flex;
                flex-flow: row wrap;
                justify-content : space-between;
                .item {
                    margin-bottom : $a50;
                    position: relative;
                    display: flex;
                    align-items : flex-end;
                    width : $a270;
                    transition: .5s all;
                    img {
                        width : 100%;
                    }
                    .bg {
                        width : 100%;
                        position: absolute;
                        bottom : 0;
                        height : $a30;
                        display: flex;
                        align-items: center;
                        justify-content : center;
                        background: rgba(0, 0, 0, 0.8);
                        .description {
                            font-family: MontserratRegular;
                            font-size: $a18;
                            line-height: $a22;
                            color: #fff;
                        }
                    }
                    &:hover {
                        transform: scale(1.1);
                    }
                }
            }
        }
    }
    .about_us {
        .container {
            padding-bottom : $a50;
            p {
                font-family: MontserratRegular;
                line-height: $a29;
                color: #000000;
                b {
                    font-family: MontserratBold;
                }
            }
            .text {
                margin: $a12 0 $a20;
            }
            .descr {
                padding-left : $a100;
                margin: $a15 0;
                li {
                    background-size: 40%;
                    padding-left : $a30;
                    background: url(../img/icons/check.svg) no-repeat ;
                    font-family: MontserratBold;
                    font-size: $a24;
                    line-height: $a29;
                    color: #000000;
                }
            }
        }
    }
    .quality {
        margin-bottom : $a50;
        .container {
            padding-bottom : $a60;
            position: relative;
            .img_abs1 {
                position: absolute;
                right : 0;
                bottom : $a20;
                z-index : 0;
                img {
                    width : $a370;
                }
            }
            .img_abs2 {
                position: absolute;
                right : $a200;
                bottom : 0;
                z-index : 1;
                img {
                    width : $a270;
                }
            }
            p {
                line-height: $a29;
            }
            .title {
                font-family: MontserratBold;
                margin-bottom : $a12;
                display: inline-block;
                border-bottom: 2px solid #000;
            }
            .button {
                margin-top : $a55;
                border: 1px solid #000;
                color: #000;
                transition: .5s all;
                &:hover {
                    background: #000;
                    color: #fff;
                }
            }
        }
    }
    .info {
        height : $a300;
        margin-bottom : $a50;
        .container {
            padding: $a53 $a70 0;
            display: flex;
            justify-content : space-between;
            align-items: flex-start;
            height : 100%;
            background: url(../img/main/metal_bg.png);
            .item {
                display: flex;
                flex-direction: column;
                align-items: center;
                img {
                    margin-bottom : $a8;
                    width : $a150;
                }
                p {
                    font-family: MontserratRegular;
                    font-size: $a12;
                    line-height: $a18;
                    text-align: center;
                    color: #fff;
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    #main {
        .about_us {
            .container {
                .descr {
                    li {
                        background-image: none;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1023px) {
    #main {
        .slider {
            .slide {
                picture {
                    img {
                        height : $m250;
                    }
                }
                .container {
                    top : $m100;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    h1 {
                        width : 100%;
                        white-space: normal;
                        font-size : $m10;
                        line-height : $m14;
                        margin-bottom : 0;
                    }
                    .button {
                        margin-top : $m30;
                        width : 50%;
                        font-size : $m12;
                        //padding : 0 $m20 !important;
                    }
                }
            }
        }
        .materials {
            .container {
                width : 100% !important;
                flex-direction: column;
                .content {
                    display: none;
                }
            }
        }
        .about_us {
            .container {
               padding-bottom : $m15;
                .text {
                    margin: $m10 0;
                }
                .descr {
                    padding-right : $m105;
                    margin: $m12 0 $m12;
                    padding-left : 0;
                    li {
                        margin-bottom : $m5;
                        padding-left : 0;
                        line-height : $m8;
                        background-image: none;
                        font-size : $m8;
                    }
                }
            }
        }
        .quality {
            .container {
                display: flex;
                flex-direction: column;
                align-items: center;
                .img_abs1, .img_abs2 {
                    display: none;
                }
                .title {
                    font-size : $m11 !important;
                    border: none;
                    text-align: center !important;
                    width : 100%;
                    padding: 0 $m80 !important;
                    line-height : $m13 !important;
                    margin-bottom : $m7;
                }
            }
        }
        .info {
            margin-bottom : $m20;
            height: auto;
            .container {
                width : 100% !important;
                padding-bottom : $m8;
                flex-flow: row wrap;
                padding-right : $m60;
                padding-left : $m60;
                justify-content : center;
                .item {
                    margin-bottom : $m4;
                    width : 30%;
                    img {
                        width : $m35;
                    }
                    p {
                        line-height : $m4 !important;
                        width : 66%;
                        font-size : $m3 !important;
                        padding-right : 0 !important;
                        text-align: center !important;
                    }
                }
                .sertificat {
                    order: 5;
                }
                .four {
                    order: 4;
                }
                .box {
                    order: 3;
                }
            }
        }
        .information_block {
            justify-content : center;
            margin-bottom : $m15;
            .container {
                align-items: center;
                display: flex;
                flex-direction: column;
                padding : 0;
                width : 50% !important;
                .abs_img1 {
                    top : $m15;
                }
                .abs_img2 {
                    top : $m15;
                }
                .bold {
                    font-size : $m9 !important;
                    border: none;
                    padding-right : 0 !important;
                    white-space: normal;
                    text-align: center;
                    margin-bottom : $m10;
                }
                .m {
                    padding: 0 0 0 $m65;
                }
                .button {
                    height : $m20;
                    padding : 0 $m25 !important;
                }
            }
        }
    }
}

@media screen and (max-width: 480px) {
    #main {
        .slider {
            margin-bottom : $m20;
            .slide {
                picture {
                    img {
                        height : $m430;
                    }
                }
                .container {
                    top : $m150;
                    h1 {
                        font-size : $m18;
                        line-height : $m24;
                    }
                    .button {
                        font-size : $m18;
                        width : 70%;
                        height : $m40;
                        //padding : 0 $m80 !important;
                    }
                }
            }
        }
        .materials {
            .container {
                width : 95% !important;
            }
        }
        .about_us {
            .container {
                p {
                    font-size : $m12 !important;
                    line-height : $m15 !important;
                }
                .text {
                    margin-top : $m5;
                    margin-bottom : $m15;
                }
                .descr {
                    padding-right : 0;
                    li {
                        margin-bottom : $m15;
                        line-height : $m15;
                        font-size : $m12;
                    }
                }
            }
        }
        .quality {
            .container {
                .title {
                    padding: 0 $m30 !important;
                    font-size : $m20 !important;
                    line-height : $m22 !important;
                    margin-bottom : $m7;
                }
                p {
                    font-size : $m12 !important;
                    line-height : $m15 !important;
                }
                .button {
                    margin-top : $m20;
                    font-size : $m15;
                    height : $m40;
                }
            }
        }
        .info {
            margin-bottom : $m20;
            height: auto;
            .container {
                padding: $m20 0 ;
                .item {
                    margin-bottom : $m10;
                    width : 33%;
                    img {
                        width : $m70;
                    }
                    p {
                        line-height : $m10 !important;
                        width : 100%;
                        font-size : $m8 !important;
                    }
                }
            }
        }
        .information_block {
            margin-bottom : $m25;
            .container {
                padding-right : $m15;
                width : 100% !important;
                .abs_img1 {
                    top : $m15;
                    img {
                        width : $m140;
                    }
                }
                .abs_img2 {
                    top : $m15;
                    img {
                        width : $m140;
                    }
                }
                .bold {
                    font-size : $m20 !important;
                    margin-bottom : $m10;
                }
                .m {
                    padding: 0 0 0 $m170;
                    font-size : $m12 !important;
                    line-height : $m15 !important;
                }
                .button {
                    margin-top : $m30;
                    height : $m40;
                    width : 70%;
                    font-size : $m14;
                }
            }
        }
    }
}